// Desktop
function setItemDesktop(key = null, value = null) {
  window.localStorage.setItem(key, value);
}

async function getItemDesktop(key = null) {
  const value = window.localStorage.getItem(key);
  return value;
}

async function removeItemDesktop(key = null) {
  window.localStorage.removeItem(key);
}

const service = {
  setItem: setItemDesktop,
  getItem: getItemDesktop,
  removeItem: removeItemDesktop,
};

export default service;
